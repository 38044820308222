import { environment } from '../../environments/environment';

class ApiUrl {
  static paths = {
    login: 'login',
    api: 'api',
    ad: 'ad',
    gruposUser: '',
    buv: 'buv',
    resendValidation: 'resend-validation',
    resetPassword: 'reset-password',
  };
}

export class UrlFactory {
  private static buvUrl: string = environment.BUVApiUrl;
  private static adUrl: string = environment.ADApiUrl;

  static adLogin(): string {
    return `${this.adUrl}/${ApiUrl.paths.login}`;
  }

  static adGruposUser(): string {
    return `${this.adUrl}/${ApiUrl.paths.gruposUser}`;
  }

  static buvLogin(): string {
    return `${this.buvUrl}/${ApiUrl.paths.api}/${ApiUrl.paths.ad}/${ApiUrl.paths.login}`;
  }

  static buvVecinos(): string {
    return `${this.buvUrl}/${ApiUrl.paths.api}/${ApiUrl.paths.buv}`;
  }

  static buvResetPassword(): string {
    return `${this.buvUrl}/${ApiUrl.paths.api}/${ApiUrl.paths.buv}/${ApiUrl.paths.resetPassword}`;
  }

  static buvResendVerificationEmail(): string {
    return `${this.buvUrl}/${ApiUrl.paths.api}/${ApiUrl.paths.buv}/${ApiUrl.paths.resendValidation}`;
  }
}
